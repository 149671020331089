<template>
  <div class="page bg-white" id="reorganize">
    <div>
      <el-tabs size="small" type="card" v-model="selectTab" @tab-click="handleClick">
        <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                     :name="item.value"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="contens">
      <!--基本信息-->
      <div v-show="selectTab == 0">
        <el-row>
          <el-form :model="logoutInfo" :rules="logoutRules" ref="logoutInfo" label-width="200px"
                   size="small" class="demo-ruleForm">
            <div class="minTitle">分类</div>
            <el-col :span="11">
              <el-form-item label="文物类别" prop="culturalRelicsCategory">
                <el-select v-model="logoutInfo.culturalRelicsCategory" clearable
                           :disabled="inputDisable"
                           @change="selectCulturalRelics"
                           placeholder="请选择文物类别" style="width: 100%;">
                  <el-option
                      v-for="item in culturalRelicsCategoryList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item v-if="logoutInfo.culturalRelicsCategory != 6" label="文物分类"
                            prop="culturalRelicsType">
                <el-select v-model="logoutInfo.culturalRelicsType" clearable
                           :disabled="inputDisable"
                           placeholder="请选择文物分类" style="width: 100%;">
                  <el-option
                      v-for="item in culturalRelicsTypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-else label="文物分类" prop="other">
                <el-input :disabled="inputDisable"
                          v-model.trim="logoutInfo.other"
                          placeholder="请填写文物分类(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="完残程度" prop="integrity"
                            :rules="[{ required: true, message: '请选择完残程度', trigger: 'change' }]">
                <el-select :disabled="inputDisable" v-model="logoutInfo.integrity"
                           placeholder="请选择完残程度"
                           style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in integrityList"
                      :key="item.id"
                      :label="item.levelName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="完残情况" prop="integrityInfo">
                <el-input :disabled="inputDisable" v-model="logoutInfo.integrityInfo"
                          placeholder="请输入完残情况(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
            </el-col>


            <el-col :span="24">
              <div class="minTitle">基础</div>
            </el-col>
            <el-col :span="11">
              <el-form-item label="名称" prop="name">
                <el-input :disabled="inputDisable"
                          v-model.trim="logoutInfo.name"
                          placeholder="请输入名称(限100字)"
                          maxlength="100" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="原名" prop="originalName">
                <el-input :disabled="inputDisable"
                          v-model.trim="logoutInfo.originalName"
                          placeholder="请输入原名(限100字)"
                          maxlength="100" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="地址及位置" prop="address">
                <el-input
                    v-model.trim="logoutInfo.address"
                    clearable
                    type="textarea"
                    :rows="3"
                    :disabled="inputDisable"
                    maxlength="200"
                    placeholder="请输入地址及位置(限200字)">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <div class="flex_b_c">
                <el-form-item label="纬度" prop="latitude">
                  <el-select v-model="logoutInfo.latitude"
                             :disabled="inputDisable" style="width: 80px"
                  >
                    <el-option
                        v-for="item in latitudeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <div class="flex_b_t">
                  <el-form-item label-width="0" prop="latitudeLimit">
                    <el-input
                        v-model.trim.number="logoutInfo.latitudeLimit"
                        :max="90"
                        :min="0"
                        type="number"
                        @input="(value)=>{longitudeSet(value,'latitudeLimit',90,0)}"
                        :disabled="inputDisable"
                    >
                    </el-input>
                  </el-form-item>
                  <div>°</div>
                </div>
                <div class="flex_b_t">
                  <el-form-item label-width="0" prop="latitudePoints">
                    <el-input
                        v-model.trim.number="logoutInfo.latitudePoints"
                        :max="60"
                        :min="0"
                        type="number"
                        @input="(value)=>{longitudeSet(value,'latitudePoints',60,0)}"
                        :disabled="inputDisable"
                    >
                    </el-input>
                  </el-form-item>
                  <div>′</div>
                </div>
                <div class="flex_b_t">
                  <el-form-item label-width="0" prop="latitudeSeconds">
                    <el-input
                        v-model.trim.number="logoutInfo.latitudeSeconds"
                        :max="60"
                        :min="0"
                        type="number"
                        @input="(value)=>{longitudeSet(value,'latitudeSeconds',60,0)}"
                        :disabled="inputDisable"
                    >
                    </el-input>
                  </el-form-item>
                  <div>″</div>
                </div>
              </div>
            </el-col>
            <el-col :span="11">
              <div class="flex_b_c">
                <el-form-item label="经度" prop="longitude">
                  <el-select v-model="logoutInfo.longitude"
                             :disabled="inputDisable" style="width: 80px"
                  >
                    <el-option
                        v-for="item in longitudeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <div class="flex_b_t">
                  <el-form-item label-width="0" prop="longitudeLimit">
                    <el-input
                        v-model.trim.number="logoutInfo.longitudeLimit"
                        :max="180"
                        :min="0"
                        type="number"
                        @input="(value)=>{longitudeSet(value,'longitudeLimit',180,0)}"
                        :disabled="inputDisable"
                    >
                    </el-input>
                  </el-form-item>
                  <div>°</div>
                </div>
                <div class="flex_b_t">
                  <el-form-item label-width="0" prop="longitudePoints">
                    <el-input
                        v-model.trim.number="logoutInfo.longitudePoints"
                        :max="60"
                        :min="0"
                        type="number"
                        @input="(value)=>{longitudeSet(value,'longitudePoints',60,0)}"
                        :disabled="inputDisable"
                    >
                    </el-input>
                  </el-form-item>
                  <div>′</div>
                </div>
                <div class="flex_b_t">
                  <el-form-item label-width="0" prop="longitudeSeconds">
                    <el-input
                        v-model.trim.number="logoutInfo.longitudeSeconds"
                        :max="60"
                        :min="0"
                        type="number"
                        @input="(value)=>{longitudeSet(value,'longitudeSeconds',60,0)}"
                        :disabled="inputDisable"
                    >
                    </el-input>
                  </el-form-item>
                  <div>″</div>
                </div>
              </div>
            </el-col>
            <el-col :span="11">
              <el-form-item label="海拔高度（m）" style="margin-bottom: 0" prop="altitude">
                <div class="flex_b_c">
                  <el-form-item label-width="0" prop="altitude">
                    <el-input
                        v-model.trim="logoutInfo.altitude"
                        @input="(value)=>{jiage(value,'altitude',2000,0)}"
                        @blur="sav2Decimal('altitude','altitude','maxAltitude')"
                        clearable
                        placeholder="请输入海拔高度"
                        :disabled="inputDisable"
                    >
                    </el-input>
                  </el-form-item>
                  <div style="width: 40px;text-align: center;margin-bottom: 18px">~</div>
                  <el-form-item label-width="0" prop="maxAltitude">
                    <el-input
                        v-model.trim.number="logoutInfo.maxAltitude"
                        @input="(value)=>{jiage(value,'maxAltitude',2000,0)}"
                        @blur="sav2Decimal('maxAltitude','altitude','maxAltitude')"
                        clearable
                        placeholder="请输入海拔高度"
                        :disabled="inputDisable"
                    >
                    </el-input>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="级别" prop="level">
                <el-select v-model="logoutInfo.level" clearable
                           :disabled="inputDisable"
                           placeholder="请选择文物类别" style="width: 100%;">
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('rank')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="测点说明" prop="pointSpecification">
                <el-input
                    v-model.trim="logoutInfo.pointSpecification"
                    clearable
                    type="textarea"
                    :rows="3"
                    :disabled="inputDisable"
                    maxlength="200"
                    placeholder="请输入测点说明(限200字)">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="统计年代" prop="statisticsS">
                <el-select v-model="logoutInfo.statisticsS" clearable
                           :disabled="inputDisable"
                           placeholder="请选择统计年代" style="width: 100%;">
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('statistics_age')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="年份（年）" prop="year">
                <el-date-picker
                    :disabled="inputDisable"
                    v-model="logoutInfo.year"
                    clearable
                    type="year"
                    value-format="yyyy"
                    style="width: 100%;"
                    placeholder="选择年份">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="面积（㎡）" prop="area">
                <el-input
                    v-model.trim="logoutInfo.area"
                    @input="(value)=>{jiage(value,'area',99999,0)}"
                    @blur="sav2Decimal('area')"
                    clearable
                    placeholder="请输入面积（㎡）"
                    :disabled="inputDisable"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="所有权" prop="ownership">
                <el-select v-model="logoutInfo.ownership" clearable
                           :disabled="inputDisable"
                           placeholder="请选择所有权" style="width: 100%;">
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('ownership_state')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="数量（个）" prop="quantity">
                <el-input
                    v-model.trim="logoutInfo.quantity"
                    @input="(value)=>{positiveInteger(value,'quantity',999,0)}"
                    clearable
                    placeholder="请输入数量（个）"
                    :disabled="inputDisable"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="数量说明" prop="quantitySpecification">
                <el-input :disabled="inputDisable"
                          v-model.trim="logoutInfo.quantitySpecification"
                          placeholder="请输入数量说明(限100字)"
                          maxlength="100" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="minTitle">文物情况</div>
            </el-col>
            <el-col :span="11">
              <el-form-item label="用途" prop="use">
                <el-select v-model="logoutInfo.use" clearable
                           :disabled="inputDisable"
                           placeholder="请选择用途" style="width: 100%;">
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('culturalUse_state')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="文物简介" prop="aboutRelics">
                <el-input
                    v-model.trim="logoutInfo.aboutRelics"
                    clearable
                    type="textarea"
                    :rows="3"
                    :disabled="inputDisable"
                    maxlength="500"
                    placeholder="请输入文物简介(限500字)">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="现状评估" prop="heritageAppraisal">
                <el-select v-model="logoutInfo.heritageAppraisal" clearable
                           :disabled="inputDisable"
                           placeholder="请选择现状评估" style="width: 100%;">
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('assessment_status')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="现状描述" prop="statusDescription">
                <el-input
                    v-model.trim="logoutInfo.statusDescription"
                    clearable
                    type="textarea"
                    :rows="3"
                    :disabled="inputDisable"
                    maxlength="500"
                    placeholder="请输入现状描述(限500字)">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="摧毁原因" prop="causeOfDestruction">
                <el-select v-model="logoutInfo.causeOfDestruction" clearable
                           :disabled="inputDisable"
                           placeholder="请选择摧毁原因" style="width: 100%;">
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('destroy_status')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="摧毁的因素分类" prop="destroyTheFactorClassification">
                <el-select v-model="logoutInfo.destroyTheFactorClassification" clearable
                           :disabled="inputDisable"
                           placeholder="请选择摧毁的因素分类" style="width: 100%;">
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('destroyClass_status')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="摧毁原因描述" prop="destructionCauseDescription">
                <el-input
                    v-model.trim="logoutInfo.destructionCauseDescription"
                    clearable
                    type="textarea"
                    :rows="3"
                    :disabled="inputDisable"
                    maxlength="500"
                    placeholder="请输入摧毁原因描述(限500字)">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="minTitle">扩充信息</div>
            </el-col>
            <el-col :span="22">
              <el-form-item label="背景调查" prop="backgroundCheck">
                <el-input
                    v-model.trim="logoutInfo.backgroundCheck"
                    clearable
                    type="textarea"
                    :rows="3"
                    :disabled="inputDisable"
                    maxlength="500"
                    placeholder="请输入背景调查描述(限500字)">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="专家讨论" prop="expertDiscussion">
                <el-input
                    v-model.trim="logoutInfo.expertDiscussion"
                    clearable
                    type="textarea"
                    :rows="3"
                    :disabled="inputDisable"
                    maxlength="500"
                    placeholder="请输入专家讨论描述(限500字)">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="修缮描述" prop="repairDescription">
                <el-input
                    v-model.trim="logoutInfo.repairDescription"
                    clearable
                    type="textarea"
                    :rows="3"
                    :disabled="inputDisable"
                    maxlength="500"
                    placeholder="请输入修缮描述(限500字)">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="修复材料描述" prop="repairMaterialDescription">
                <el-input
                    v-model.trim="logoutInfo.repairMaterialDescription"
                    clearable
                    type="textarea"
                    :rows="3"
                    :disabled="inputDisable"
                    maxlength="500"
                    placeholder="请输入修复材料描述(限500字)">
                </el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>

      <!--图片-->
      <div v-show="selectTab == 1" class="zheaug">
        <resume-upload ref="resume-upload1" :uploadNum="imgFileNumr" :fileType="0" :allowFiles="imgPattern"
                       @upFileDate="(data)=>getUpData(data,1)" :upDateType="'img'"></resume-upload>
        <div v-show="inputDisable" class="zheuag" @click.stop></div>
        <el-table
            v-show="imgDataList.length"
            :data="imgDataList"
            size="small"
            ref="multipleTable"
            class="table"
        >
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="name" label="文件预览" width="120px">
            <template slot-scope="scope">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.netUrl"
                  :fit="'cover'"
                  :preview-src-list="[scope.row.netUrl]"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="文件名称" width="200px">
            <template slot-scope="scope">
              <el-input :disabled="inputDisable" v-model="scope.row.fileName" maxLength="20" clearable
                        size="small"
                        placeholder="请输入文件名称"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="fileFormat" label="文件格式" show-overflow-tooltip></el-table-column>
          <el-table-column prop="fileSize" label="文件大小" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ (scope.row.fileSize / 1024 / 1024).toFixed(2) }}M</div>
            </template>
          </el-table-column>
          <el-table-column prop="fileRes" label="分辨率" show-overflow-tooltip></el-table-column>
          <!--                    <el-table-column prop="fileSort" label="顺序" width="50"></el-table-column>-->
          <el-table-column prop="createDate" label="上传时间" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div v-if="!inputDisable">
                <el-button
                    v-if="scope.row.masterView == 1 && imgDataList.filter(item=>{ return  item.masterView != 1}).length != 1"
                    size="mini" type="primary" plain @click="mainDisplay(0, scope.row)">设为主显
                </el-button>
                <el-button v-if="scope.row.masterView == 0" size="mini" type="primary" plain
                           @click="mainDisplay(1, scope.row)">取消主显
                </el-button>
                <el-button size="mini" type="primary" plain
                           @click="deteliFile(1, scope.row,scope.$index)">
                  删除
                </el-button>
              </div>
              <el-button v-else-if="butType != 0" size="mini" type="primary" plain
                         @click="downFiile(scope.row.netUrl,scope.row.fileName)">
                下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--音频-->
      <div v-show="selectTab == 2" class="zheaug">
        <resume-upload ref="resume-upload2" :uploadNum="mpFileNumr" :fileType="1" :allowFiles="mpPattern"
                       @upFileDate="(data)=>getUpData(data,2)"
                       :upFileSize="maFileSize" :upDateType="'music'"></resume-upload>
        <div v-show="inputDisable" class="zheuag" @click.stop></div>
        <el-table
            v-show="mp3DataList.length"
            :data="mp3DataList"
            size="small"
            ref="multipleTable"
            class="table"
        >
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="url" label="文件预览" width="400px">
            <template slot-scope="scope">
              <audio class="audioDom" style="width: 100%" controls :src="scope.row.netUrl"></audio>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="文件名称" width="200px">
            <template slot-scope="scope">
              <el-input :disabled="inputDisable" v-model="scope.row.fileName" maxLength="20" clearable
                        size="small"
                        placeholder="请输入文件名称"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="fileFormat" label="文件格式" show-overflow-tooltip></el-table-column>
          <el-table-column prop="fileSize" label="文件大小" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ (scope.row.fileSize / 1024 / 1024).toFixed(2) }}M</div>
            </template>
          </el-table-column>
          <el-table-column prop="createDate" label="上传时间" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div v-if="!inputDisable">
                <el-button size="mini" type="primary" plain @click="deteliFile(2, scope.row)">删除
                </el-button>
              </div>
              <el-button v-else-if="butType != 0" size="mini" type="primary" plain
                         @click="downFiile(scope.row.netUrl,scope.row.fileName)">
                下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--视频-->
      <div v-show="selectTab == 3" class="zheaug">
        <div v-show="inputDisable" class="zheuag" @click.stop></div>
        <resume-upload ref="resume-upload3" :uploadNum="viFileNumr" :fileType="2" :allowFiles="viPattern"
                       @upFileDate="(data)=>getUpData(data,3)" :upFileSize="viFileSize"
                       :upDateType="'video'"></resume-upload>
        <el-table
            v-show="videoDataList.length"
            :data="videoDataList"
            size="small"
            ref="multipleTable"
            class="table"
        >
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="url" label="文件预览" width="200px">
            <template slot-scope="scope">
              <video class="videoDom" style="width: 190px;" controls :src="scope.row.netUrl"></video>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="文件名称" width="200px">
            <template slot-scope="scope">
              <el-input :disabled="inputDisable" v-model="scope.row.fileName" maxLength="20" clearable
                        size="small"
                        placeholder="请输入文件名称"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="fileFormat" label="文件格式" show-overflow-tooltip></el-table-column>
          <el-table-column prop="fileSize" label="文件大小" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ (scope.row.fileSize / 1024 / 1024).toFixed(2) }}M</div>
            </template>
          </el-table-column>
          <el-table-column prop="fileRes" label="分辨率" show-overflow-tooltip></el-table-column>
          <el-table-column prop="fileFrame" label="每秒传输帧数" show-overflow-tooltip></el-table-column>
          <el-table-column prop="fileDuration" label="时长（分钟）" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createDate" label="上传时间" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div v-if="!inputDisable">
                <el-button size="mini" type="primary" plain @click="deteliFile(3, scope.row)">删除
                </el-button>
              </div>
              <el-button v-else-if="butType != 0" size="mini" type="primary" plain
                         @click="downFiile(scope.row.netUrl,scope.row.fileName)">
                下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--文献资料-->
      <div v-show="selectTab == 4" class="zheaug">
        <div v-show="inputDisable" class="zheuag" @click.stop></div>
        <resume-upload ref="resume-upload4" :uploadNum="literFileNumr" :fileType="3" :allowFiles="literPattern"
                       @upFileDate="(data)=>getUpData(data,4)" :upFileSize="literFileSize"
                       :upDateType="'literature'"></resume-upload>
        <el-table
            v-show="literDataList.length"
            :data="literDataList"
            size="small"
            ref="multipleTable"
            class="table"
        >
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="url" label="文件预览" width="120px">
            <template slot-scope="scope">
              <i @click="previewPdf(scope.row.netUrl)" class="el-icon-tickets fileIcon"></i>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="文件名称" width="200px">
            <template slot-scope="scope">
              <el-input :disabled="inputDisable" v-model="scope.row.fileName" maxLength="20" clearable
                        size="small"
                        placeholder="请输入文件名称"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="fileFormat" label="文件格式" show-overflow-tooltip></el-table-column>
          <el-table-column prop="fileSize" label="文件大小" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ (scope.row.fileSize / 1024 / 1024).toFixed(2) }}M</div>
            </template>
          </el-table-column>
          <!--                    <el-table-column label="顺序" prop="fileSort" width="50"></el-table-column>-->
          <el-table-column prop="createDate" label="上传时间" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div v-if="!inputDisable">
                <el-button size="mini" type="primary" plain @click="deteliFile(4, scope.row)">删除
                </el-button>
              </div>
              <el-button v-else-if="butType != 0" size="mini" type="primary" plain
                         @click="downFiile(scope.row.netUrl,scope.row.fileName)">
                下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--鉴定资料-->
      <div v-show="selectTab == 5" class="zheaug">
        <div v-show="inputDisable" class="zheuag" @click.stop></div>
        <resume-upload ref="resume-upload5" :uploadNum="literFileNumr" :fileType="4" :allowFiles="literPattern"
                       @upFileDate="(data)=>getUpData(data,5)" :upFileSize="literFileSize"
                       :upDateType="'information'"></resume-upload>
        <el-table
            v-show="zilDataList.length"
            :data="zilDataList"
            size="small"
            ref="multipleTable"
            class="table"
        >
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="url" label="文件预览" width="120px">
            <template slot-scope="scope">
              <i @click="previewPdf(scope.row.netUrl)" class="el-icon-tickets fileIcon"></i>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="文件名称" width="200px">
            <template slot-scope="scope">
              <el-input :disabled="inputDisable" v-model="scope.row.fileName" maxLength="20" clearable
                        size="small"
                        placeholder="请输入文件名称"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="fileFormat" label="文件格式" show-overflow-tooltip></el-table-column>
          <el-table-column prop="fileSize" label="文件大小" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ (scope.row.fileSize / 1024 / 1024).toFixed(2) }}M</div>
            </template>
          </el-table-column>
          <!--                    <el-table-column label="顺序" prop="fileSort" width="50"></el-table-column>-->
          <el-table-column prop="createDate" label="上传时间" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div v-if="!inputDisable">
                <el-button size="mini" type="primary" plain @click="deteliFile(5, scope.row)">删除
                </el-button>
              </div>
              <el-button v-else-if="butType != 0" size="mini" type="primary" plain
                         @click="downFiile(scope.row.netUrl,scope.row.fileName)">
                下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--证明资料-->
      <div v-show="selectTab == 6" class="zheaug">
        <div v-show="inputDisable" class="zheuag" @click.stop></div>
        <resume-upload ref="resume-upload6" :uploadNum="literFileNumr" :fileType="7" :allowFiles="literPattern"
                       @upFileDate="(data)=>getUpData(data,6)" :upFileSize="literFileSize"
                       :upDateType="'prove'"></resume-upload>
        <el-table
            v-show="proveDataList.length"
            :data="proveDataList"
            size="small"
            ref="multipleTable"
            class="table"
        >
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="url" label="文件预览" width="120px">
            <template slot-scope="scope">
              <i @click="previewPdf(scope.row.netUrl)" class="el-icon-tickets fileIcon"></i>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="文件名称" width="200px">
            <template slot-scope="scope">
              <el-input :disabled="inputDisable" v-model="scope.row.fileName" maxLength="20" clearable
                        size="small"
                        placeholder="请输入文件名称"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="fileFormat" label="文件格式" show-overflow-tooltip></el-table-column>
          <el-table-column prop="fileSize" label="文件大小" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ (scope.row.fileSize / 1024 / 1024).toFixed(2) }}M</div>
            </template>
          </el-table-column>
          <!--                    <el-table-column label="顺序" prop="fileSort" width="50"></el-table-column>-->
          <el-table-column prop="createDate" label="上传时间" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div v-if="!inputDisable">
                <el-button size="mini" type="primary" plain @click="deteliFile(6, scope.row)">删除
                </el-button>
              </div>
              <el-button v-else-if="butType != 0" size="mini" type="primary" plain
                         @click="downFiile(scope.row.netUrl,scope.row.fileName)">
                下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--三维模型-->
      <div v-show="selectTab == 7" class="zheaug">
        <resume-upload ref="resume-upload7" :uploadNum="threeFileNumr" :fileType="6" :allowFiles="threePattern"
                       @upFileDate="(data)=>getUpData(data,7)" :threeFile="true" :disabledBut="inputDisable"
                       :upFileSize="threeFileSize"></resume-upload>
        <el-table
            v-show="threeDataList.length"
            :data="threeDataList"
            size="small"
            ref="multipleTable"
            class="table"
        >
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="url" label="文件预览" width="120px">
            <template slot-scope="scope">
              <i @click="threeFile(scope.row)" class="el-icon-football fileIcon"></i>
            </template>
          </el-table-column>
          <el-table-column prop="model" label="数据分级" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.model == 0 ? '高模' : scope.row.model == 1 ? '中模' : '低模' }}
            </template>
          </el-table-column>
          <el-table-column prop="fileFormat" label="文件类型" show-overflow-tooltip>
            <template slot-scope="scope">
              三维资源
            </template>
          </el-table-column>
          <el-table-column prop="compressName" label="文件名称" width="200px">
            <template slot-scope="scope">
              <el-input :disabled="inputDisable" v-model="scope.row.compressName" maxLength="20" clearable
                        size="small"
                        placeholder="请输入文件名称"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="fileSize" label="文件大小" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ (scope.row.fileSize / 1024 / 1024).toFixed(2) }}M</div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div v-if="!inputDisable">
                <el-button size="mini" type="primary" plain @click="deteliFile(7, scope.row)">删除
                </el-button>
              </div>
              <el-button v-else-if="butType != 0" size="mini" type="primary" plain
                         @click="downFiile(scope.row.compressUrl,scope.row.compressName)">
                下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--设计文件-->
      <div v-show="selectTab == 8" class="zheaug">
        <div v-show="inputDisable" class="zheuag" @click.stop></div>
        <resume-upload ref="resume-upload8" :uploadNum="designFileNumr" :fileType="8"
                       :allowFiles="designPattern"
                       @upFileDate="(data)=>getUpData(data,8)" :upDateType="'design'"
                       :upFileSize="designFileSize"></resume-upload>
        <el-table
            v-show="designDataList.length"
            :data="designDataList"
            size="small"
            ref="multipleTable"
            class="table"
        >
          <el-table-column type="selection" width="50"/>
          <el-table-column prop="fileName" label="文件名称" width="200px">
            <template slot-scope="scope">
              <el-input :disabled="inputDisable" v-model="scope.row.fileName" maxLength="20" clearable
                        size="small"
                        placeholder="请输入文件名称"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="fileFormat" label="文件格式" show-overflow-tooltip></el-table-column>
          <el-table-column prop="fileSize" label="文件大小" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ (scope.row.fileSize / 1024 / 1024).toFixed(2) }}M</div>
            </template>
          </el-table-column>
          <el-table-column label="顺序" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="createDate" label="上传时间" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div v-if="!inputDisable">
                <el-button size="mini" type="primary" plain @click="deteliFile(8, scope.row)">删除
                </el-button>
              </div>
              <el-button v-else-if="butType != 0" size="mini" type="primary" plain
                         @click="downFiile(scope.row.netUrl,scope.row.fileName)">
                下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--修复资料-->
      <div v-if="selectTab == 9">
        <repairs ref="repairs" :typePage="typePage" :id="id" pageType="addMovable" :selectTab="selectTab" @gotoOtherDetail="gotoOtherDetail"></repairs>
      </div>

      <!--保护资料-->
      <div v-if="selectTab == 10">
        <protect ref="protect" :id="id" :selectTab="selectTab" @gotoOtherDetail="gotoOtherDetail"></protect>
      </div>

      <!--事故资料-->
      <div v-if="selectTab == 11">
        <accident ref="accident" :id="id" :selectTab="selectTab" pageType="immove" @gotoOtherDetail="gotoOtherDetail"></accident>
      </div>


    </div>
    <div v-if="butType != 0 && butType != 2 && butType != 4"
         class="text_center">
      <el-button size="small" v-if="!see" type="primary" @click="switchData(1)">上一条</el-button>
      <el-button size="small" v-if="!see" type="primary" @click="switchData(0)">下一条</el-button>

      <el-button size="small" v-if="butType == 3 && logoutInfo.processState == 1 && see != 1" type="primary"
                 @click="thread(3)">通过
      </el-button>
      <el-button size="small" v-if="butType == 3 && logoutInfo.processState == 1 && see != 1" type="danger"
                 @click="thread(2)">驳回
      </el-button>
      <el-button size="small" @click="returnPage(1)">关闭</el-button>
    </div>
    <div v-else-if="!inputDisable" class="text_center">
      <el-button size="small" @click="returnPage(1)">取消</el-button>
      <el-button size="small" type="primary" @click="pagePreview()">预览</el-button>
    </div>
    <div v-else class="text_center">
      <el-button size="small" type="primary" @click="backDate()">上一步</el-button>
      <el-button size="small" v-if="butType == 2 || butType == 4" type="primary" @click="saveAsDraft(1)">保存
      </el-button>
      <el-button size="small" v-if="butType == 0" type="primary" @click="saveAsDraft(1)">保存为草稿
      </el-button>
      <el-button size="small" v-if="butType == 0 || butType == 4" type="primary" @click="saveAsDraft(0)">提交
      </el-button>
    </div>
    <reviewSuggestions ref="suggestions" @updataStatus="returnPage()"></reviewSuggestions>
    <see-three-file ref="seethree"></see-three-file>
  </div>
</template>

<script>
import resumeUpload from "@/components/Upload/resumeUpload.vue";
import pdf from "vue-pdf";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";
import treeSelect from "@/components/treeSelect/treeSelect.vue";
import SelectTree from "@/components/treeSelect/treeSelect.vue";
import {Base64} from 'js-base64'
import SeeThreeFile from "@/views/modules/collection/accounts/module/seeThreeFile.vue";
import Repairs from "@/views/modules/collection/accounts/detailModule/repairs.vue";
import Accident from "@/views/modules/collection/accounts/detailModule/accident.vue";
import Protect from "@/views/modules/collection/accounts/detailModule/protect.vue";

export default {
  name: "addCollection",
  components: {Protect, Accident, Repairs, SeeThreeFile, SelectTree, resumeUpload, pdf, reviewSuggestions, treeSelect},
  data() {
    return {
      selectTab: 0,
      tabsList: [],

      //元数据字段
      loading: false,

      id: '',
      logoutInfo: {
        culturalRelicsCategory: '',
        culturalRelicsType: '',
        name: '',
        originalName: '',
        address: '',
        latitude: '0',
        latitudeLimit: '',
        latitudePoints: '',
        latitudeSeconds: '',
        longitude: '0',
        longitudeLimit: '',
        longitudePoints: '',
        longitudeSeconds: '',
        altitude: '',
        level: '',
        pointSpecification: '',
        statisticsS: '0',
        area: '',
        ownership: '',
        quantity: '',
        quantitySpecification: '',
        use: '',
        aboutRelics: '',
        heritageAppraisal: '',
        statusDescription: '',
        causeOfDestruction: '',
        destroyTheFactorClassification: '',
        destructionCauseDescription: '',
        backgroundCheck: '',
        expertDiscussion: '',
        repairDescription: '',
        repairMaterialDescription: '',
        other: '',
        integrity: '',
        integrityInfo: '',
        maxAltitude: '',
      },
      logoutRules: {
        culturalRelicsCategory: [
          {required: true, message: '请选择文物类别', trigger: 'change'}
        ],
        culturalRelicsType: [
          {required: true, message: '请选择文物分类', trigger: 'change'}
        ],
        other: [{required: true, message: '请填写文物分类', trigger: 'blur'}],
        name: [{required: true, message: '请输入名称', trigger: 'blur'}],
        address: [{required: true, message: '请输入地址及位置', trigger: 'blur'}],
        latitude: [
          {required: true, message: '请选择纬度', trigger: 'change'}
        ],
        latitudeLimit: [{required: true, message: '请输入°', trigger: 'blur'}],
        latitudePoints: [{required: true, message: '请输入′', trigger: 'blur'}],
        latitudeSeconds: [{required: true, message: '请输入″', trigger: 'blur'}],
        longitude: [
          {required: true, message: '请选择经度', trigger: 'change'}
        ],
        longitudeLimit: [{required: true, message: '请输入°', trigger: 'blur'}],
        longitudePoints: [{required: true, message: '请输入′', trigger: 'blur'}],
        longitudeSeconds: [{required: true, message: '请输入″', trigger: 'blur'}],
        altitude: [{required: true, message: '请输入海拔高度', trigger: 'blur'}],
        maxAltitude: [{required: true, message: '请输入海拔高度', trigger: 'blur'}],
        quantity: [{required: true, message: '请输入数量', trigger: 'blur'}],
      },
      culturalRelicsCategoryList: [],
      culturalRelicsTypeList: [],
      integrityList: [
        {
          id: '残缺',
          levelName: '残缺',
        },
        {
          id: '基本完整',
          levelName: '基本完整',
        },
        {
          id: '完整',
          levelName: '完整',
        },
        {
          id: '严重残缺',
          levelName: '严重残缺',
        },
      ],
      latitudeList: [
        {
          value: '0',
          label: '北纬',
        },
        {
          value: '1',
          label: '南纬',
        },
      ],
      longitudeList: [
        {
          value: '0',
          label: '东经',
        },
        {
          value: '1',
          label: '西经',
        },
      ],

      //图片上传限制
      imgPattern: ['PNG', 'BMP', 'JPG', 'JPEG', 'GIF'],
      imgFileNumr: 20,

      //音频上传限制
      mpPattern: ['MP3'],
      mpFileNumr: 10,
      maFileSize: 100,

      //视频上传限制
      viPattern: ['MP4'],
      viFileNumr: 10,
      viFileSize: 100,

      //文献、鉴定、证明资料上传限制
      literPattern: ['PDF'],
      literFileNumr: 30,
      literFileSize: 30,

      //三维文件
      threePattern: ['RAR', 'STL', 'ZIP', '7Z'],
      threeFileNumr: 3,
      threeFileSize: 0,

      //设计文件
      designPattern: ['ZIP', 'RAR', '7Z', 'TAR', 'GZ'],
      designFileNumr: 10,
      designFileSize: 0,

      imgDataList: [],
      mp3DataList: [],
      videoDataList: [],
      literDataList: [],
      zilDataList: [],
      proveDataList: [],
      threeDataList: [],
      designDataList: [],

      pdfUrl: '',
      typePage: '', //页面的标识
      inputDisable: false,//是否禁用所有藏品输入框
      butType: 0, //页面按钮状态 0新增 1详情页 2修改 3审核 4重新提交
      programExit: false,//是否用代码退出
      see: false,

      registrationSearch: {}, //上一条下一条存储

      returnPageStatus: null,//判断是否是点返回或退出按钮
    }
  },

  mounted() {
    //页面按钮控制
    this.butType = this.$route.query.butType
    this.typePage = this.$route.query.typePage
    this.see = this.$route.query.see
    this.setPageTitle()
    if (this.butType == 1 || this.butType == 3) {
      this.inputDisable = true
    }

    //获取选择下拉框
    this.getSelectData()
    this.id = this.$route.query.id
    if (this.id) {
      this.getDataDetail()
    }
    this.setTabData()
    //详情查看的返回缓存
    this.getReturnSearchDate()
  },

  methods: {
    getReturnSearchDate(){
      let listSearchCollDetail = JSON.parse(sessionStorage.getItem('listSearchCollDetail'))
      if (listSearchCollDetail) {
        this.selectTab = listSearchCollDetail.selectTab;
        sessionStorage.removeItem('listSearchCollDetail')
        setTimeout(() => {
          if (listSearchCollDetail.selectTab == 9) { //修复资料
            this.$refs.repairs.inputForm = listSearchCollDetail.inputForm
            this.$refs.repairs.current = listSearchCollDetail.current
            this.$refs.repairs.size = listSearchCollDetail.size
            this.$refs.repairs.getDataList()
          }
          if (listSearchCollDetail.selectTab == 10) { //保护资料
            this.$refs.protect.inputForm = listSearchCollDetail.inputForm
            this.$refs.protect.current = listSearchCollDetail.current
            this.$refs.protect.size = listSearchCollDetail.size
            this.$refs.protect.getDataList()
          }
          if (listSearchCollDetail.selectTab == 11) { //事故资料
            this.$refs.accident.inputForm = listSearchCollDetail.inputForm
            this.$refs.accident.current = listSearchCollDetail.current
            this.$refs.accident.size = listSearchCollDetail.size
            this.$refs.accident.getDataList()
          }
        })
      }
    },

    //获取详情
    getDataDetail() {
      this.$axios(this.api.collection.collectionImmovableCulturalRelicGetById + this.id, {}, 'get').then((res) => {
        if (res.status) {
          this.$axios(this.api.collection.listFileByDataId, {
            dataId: this.id,
          }, 'get').then((data) => {
            if (data.status) {
              this.logoutInfo = res.data
              this.logoutInfo.culturalRelicsCategory = this.logoutInfo.culturalRelicsCategory * 1
              this.selectCulturalRelics(this.logoutInfo.culturalRelicsCategory, 1)
              this.logoutInfo.culturalRelicsType = res.data.culturalRelicsType * 1
              this.logoutInfo.latitude = this.logoutInfo.latitude + ''
              this.logoutInfo.longitude = this.logoutInfo.longitude + ''
              if (this.logoutInfo.statisticsS || this.logoutInfo.statisticsS === 0) {
                this.logoutInfo.statisticsS = this.logoutInfo.statisticsS + ''
              } else {
                this.logoutInfo.statisticsS = ''
              }
              if (this.logoutInfo.level || this.logoutInfo.level === 0) {
                this.logoutInfo.level = this.logoutInfo.level + ''
              } else {
                this.logoutInfo.level = ''
              }

              this.imgDataList = data.data.picList
              this.$refs['resume-upload1'].setOldFile(this.imgDataList)
              this.mp3DataList = data.data.audioList
              this.$refs['resume-upload2'].setOldFile(this.mp3DataList)
              this.videoDataList = data.data.videoList
              this.$refs['resume-upload3'].setOldFile(this.videoDataList)
              this.literDataList = data.data.literatureList || []
              this.$refs['resume-upload4'].setOldFile(this.literDataList)
              this.zilDataList = data.data.identifyList || []
              this.$refs['resume-upload5'].setOldFile(this.zilDataList)
              this.proveDataList = data.data.authList
              this.$refs['resume-upload6'].setOldFile(this.proveDataList)
              this.threeDataList = data.data.compressFileList || []
              this.$refs['resume-upload7'].setOldFile(this.threeDataList)
              this.designDataList = data.data.designList || []
              this.$refs['resume-upload8'].setOldFile(this.designDataList)
            }
          })
        }
      })
    },


    getSelectData() {
      this.$axios(this.api.collection.listAll).then(data => {
        if (data.status) {
          this.culturalRelicsCategoryList = data.data
        }
      })
    },

    //选中文物类别
    selectCulturalRelics(data, type) {
      if (!type) {
        this.logoutInfo.culturalRelicsType = ''
        this.logoutInfo.other = ''
      }
      this.$axios(this.api.collection.collectionImmovableCulturalRelicType + data, {}, 'get').then(data => {
        if (data.status) {
          this.culturalRelicsTypeList = data.data
          console.log(this.culturalRelicsTypeList)
        }
      })
    },

    //预览三维
    threeFile(threedate) {
      console.log(threedate)
      threedate.threeList.img.forEach(item => {
        this.$set(item, 'url', item.netUrl)
      })
      this.$refs.seethree.init(threedate.threeList)
    },

    //切换信息分类
    handleClick(tab, event) {
      if (this.selectTab != 2) {
        let audioList = document.getElementsByClassName('audioDom')
        audioList.forEach(item => {
          item.pause()
        })
      }
      if (this.selectTab != 3) {
        let videoList = document.getElementsByClassName('videoDom')
        videoList.forEach(item => {
          item.pause()
        })
      }
    },

    //设置主显 num 1设置主显 2取消主显
    mainDisplay(num, row) {
      row.masterView = num
    },

    //获取上传文件
    getUpData(data, num) {
      console.log(data)
      let dataList = data.map((item, index) => {
        if (item.upDate) {
          this.$set(item.upDate, 'fileID', item.id)
          this.$set(item.upDate, 'fileSort', index + 1)
          this.$set(item.upDate, 'masterView', 1)
          this.$set(item.upDate, 'fileSize', item.origSize)
          return item.upDate
        } else {
          return item
        }
      })
      if (num == 1) {
        this.imgDataList = dataList
        let zhuXian = this.imgDataList.filter(item => {
          return item.masterView == 0
        })
        if (zhuXian.length == 0) {
          this.imgDataList[0].masterView = 0
        }
      }
      if (num == 2) {
        this.mp3DataList = dataList
      }
      if (num == 3) {
        this.videoDataList = dataList
      }
      if (num == 4) {
        this.literDataList = dataList
      }
      if (num == 5) {
        this.zilDataList = dataList
      }
      if (num == 6) {
        this.proveDataList = dataList
      }
      if (num == 7) {
        this.threeDataList = dataList
      }
      if (num == 8) {
        console.log(dataList)
        this.designDataList = dataList
      }
    },

    //删除文件 type 1图片 2音频 3视频 4文献 5鉴定 6证明 7三维
    deteliFile(type, row, index) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (type == 1) {
          this.imgDataList.splice(index, 1)
          this.$refs["resume-upload1"].deleteFile(row.fileID ? row.fileID : row.id)
        }
        if (type == 2) {
          this.mp3DataList.splice(index, 1)
          this.$refs["resume-upload2"].deleteFile(row.fileID ? row.fileID : row.id)
        }
        if (type == 3) {
          this.videoDataList.splice(index, 1)
          this.$refs["resume-upload3"].deleteFile(row.fileID ? row.fileID : row.id)
        }
        if (type == 4) {
          this.literDataList.splice(index, 1)
          this.$refs["resume-upload4"].deleteFile(row.fileID ? row.fileID : row.id)
        }
        if (type == 5) {
          this.zilDataList.splice(index, 1)
          this.$refs["resume-upload5"].deleteFile(row.fileID ? row.fileID : row.id)
        }
        if (type == 6) {
          this.proveDataList.splice(index, 1)
          this.$refs["resume-upload5"].deleteFile(row.fileID ? row.fileID : row.id)
        }
        if (type == 7) {
          this.threeDataList.splice(index, 1)
          this.$refs["resume-upload7"].deleteFile(row.fileID ? row.fileID : row.id)
        }
        if (type == 8) {
          this.designDataList.splice(index, 1)
          this.$refs["resume-upload8"].deleteFile(row.fileID ? row.fileID : row.id)
        }
      }).catch(() => {
      })
    },

    //pdf预览
    previewPdf(url) {
      this.pdfUrl = url
      window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + encodeURIComponent(Base64.encode(this.pdfUrl)));
    },

    //预览
    pagePreview() {
      this.$refs['logoutInfo'].validate((valid) => {
        if (valid) {
          this.inputDisable = true
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },

    //上一步
    backDate() {
      this.inputDisable = false
    },

    // type 1保存  0提交
    saveAsDraft(type) {
      let datalad = {
        ...this.logoutInfo,
        files: [
          ...this.imgDataList,
          ...this.mp3DataList,
          ...this.videoDataList,
          ...this.literDataList,
          ...this.zilDataList,
          ...this.designDataList,
        ],
        compressFileList: this.threeDataList
      }
      if (type == 0) {
        this.$axios(this.api.collection.collectionImmovableCulturalRelicSubmit, datalad, 'post').then((res) => {
          if (res.status) {
            this.$message.success(`提交成功`)
            this.programExit = true
            this.returnPage(1)
          } else {
            this.$message.error(res.msg);
          }
        })
      } else {
        this.$axios(this.api.collection.collectionImmovableCulturalRelic, datalad, 'post').then((res) => {
          if (res.status) {
            this.$message.success(`保存成功`)
            this.programExit = true
            this.returnPage(1)
          } else {
            this.$message.error(res.msg);
          }
        })
      }
    },

    //1为上一条 0为下一条
    switchData(num) {
      if (Object.keys(this.registrationSearch).length == 0) {
        this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
      }
      this.$axios(this.api.collection.listCollectionRevdInfoNextOrPreRelic, {
        nextInfo: num,
        ...this.registrationSearch
      }, 'post').then((res) => {
        if (res.status) {
          if (res.data) {
            this.id = res.data.id
            this.getDataDetail()
            if (num == 0) {
              this.registrationSearch.viewCurrent++
            } else {
              this.registrationSearch.viewCurrent--
            }
          } else {
            this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
          }
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    //返回
    returnPage(num) {
      this.returnPageStatus = num
      this.$router.back()
    },

    //去藏品业务中的详情页
    gotoOtherDetail(rote){
      this.returnPageStatus = 1
      this.$router.push(rote)
    },

    //num 3为通过 2为驳回
    thread(num) {
      let revdId
      // if (this.typePage == 'cancellationReview') {
      //     revdId = 6
      // } else {
      //     revdId = 5
      // }
      let url = this.api.collection.relicRevd
      this.$refs.suggestions.init(2, [this.logoutInfo], num, 10, url)
    },

    //经纬度限制，(小数点后面不限制)
    longitudeSet(value, name, max, min) {
      // 使用正则表达式来限制输入为正数，并保留两位小数
      value = value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      value = value.replace(/^\./g, ""); //验证第一个字符是数字
      value = value.replace(/\.{2,}/g, "."); //只保留第一个, 清除多余的
      value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      //限制大小
      if (max && this.logoutInfo[name] > max) {
        value = max
      }
      if (min && this.logoutInfo[name] < min) {
        value = min
      }
      this.logoutInfo[name] = value
    },

    //value 输入的值 name名称 max最大值 min最小值 (保留两位小数)
    jiage(value, name, max, min) {
      // 使用正则表达式来限制输入为正数，并保留两位小数
      value = value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      value = value.replace(/^\./g, ""); //验证第一个字符是数字
      value = value.replace(/\.{2,}/g, "."); //只保留第一个, 清除多余的
      value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两位个小数

      //限制大小
      if (max && this.logoutInfo[name] > max) {
        value = max
      }
      if (min && this.logoutInfo[name] < min) {
        value = min
      }
      this.logoutInfo[name] = value
    },

    //保留两位小数  minName,maxName判断是否要比较两个数据的大小，min比max大就替换
    sav2Decimal(moderNum, minName, maxName) {
      if (this.logoutInfo[moderNum] && this.logoutInfo[moderNum] != 0) {
        this.logoutInfo[moderNum] = this.logoutInfo[moderNum] * 1
        this.logoutInfo[moderNum] = this.logoutInfo[moderNum].toFixed(2)
      }
      if (minName && this.logoutInfo[minName] !== '' && maxName && this.logoutInfo[maxName] !== '' && this.logoutInfo[minName] * 1 > this.logoutInfo[maxName] * 1) {
        let data = this.logoutInfo[maxName]
        this.logoutInfo[maxName] = this.logoutInfo[minName]
        this.logoutInfo[minName] = data
      }
    },

    //正整数
    positiveInteger(value, name, max, min) {
      // 使用正则表达式来限制输入为正数
      value = value.replace(/[^\d]/g, ''); //清除"数字"以外的字符

      //限制大小
      if (max && this.logoutInfo[name] > max) {
        value = max
      }
      if (min && this.logoutInfo[name] < min) {
        value = min
      }
      this.logoutInfo[name] = value
    },

    downFiile(url, name) {
      const xhr = new XMLHttpRequest();
      // 使用open()方法初始化一个请求，第一个参数为请求的类型，第二个参数为请求的地址，第三个参数为是否异步
      xhr.open('GET', url, true);
      // 设置响应的数据类型
      xhr.responseType = 'blob';
      // 当请求加载完成时，触发onload事件
      xhr.onload = () => {
        // 如果请求的状态码为200，表示请求成功
        if (xhr.status === 200) {
          // 创建一个blob对象，第一个参数为响应的数据，第二个参数为blob对象的类型
          const blob = new Blob([xhr.response], {type: xhr.getResponseHeader('content-type')});
          // 创建一个a标签
          const link = document.createElement('a');
          // 为a标签设置href属性，值为blob对象的URL
          link.href = URL.createObjectURL(blob);
          // 为a标签设置下载文件名
          link.download = name;
          // 点击a标签，开始下载文件
          link.click();
        }
      };
      // 发送请求
      xhr.send();
    },

    setTabData() {
      this.tabsList = [
        {
          name: '基本信息',
          value: '0',
        },
      ]
      if (this.hasPermissionButton(`allDetail:picture`)) {
        this.tabsList.push(
            {
              name: '图片',
              value: '1',
            },
        )
      }
      if (this.hasPermissionButton(`allDetail:audio`)) {
        this.tabsList.push(
            {
              name: '音频',
              value: '2',
            },
        )
      }
      if (this.hasPermissionButton(`allDetail:video`)) {
        this.tabsList.push(
            {
              name: '视频',
              value: '3',
            },
        )
      }
      if (this.hasPermissionButton(`allDetail:literature`)) {
        this.tabsList.push(
            {
              name: '文献资料',
              value: '4',
            },
        )
      }
      if (this.hasPermissionButton(`allDetail:appraisal`)) {
        this.tabsList.push(
            {
              name: '鉴定资料',
              value: '5',
            },
        )
      }
      if (this.hasPermissionButton(`allDetail:three`)) {
        this.tabsList.push(
            {
              name: '三维模型',
              value: '7',
            },
        )
      }
      if (this.hasPermissionButton(`allDetail:design`)) {
        this.tabsList.push(
            {
              name: '设计文件',
              value: '8',
            },
        )
      }
      // if (this.butType != 0) {
      //   this.tabsList.push(
      //       {
      //         name: '修复资料',
      //         value: '9',
      //       },
      //       {
      //         name: '保护资料',
      //         value: '10',
      //       },
      //       {
      //         name: '事故资料',
      //         value: '11',
      //       },
      //   )
      // }

    },

    setPageTitle() {
      let name = '不可移动文物详情'
      if (this.butType == 0) {
        name = '新增不可移动文物'
      } else if (this.butType == 2) {
        name = '修改不可移动文物'
      } else if (this.butType == 2) {
        name = '重新提交不可移动文物'
      }
      this.$store.state.tagsView.visitedViews.forEach(item => {
        if (item.fullPath === this.$route.fullPath) {
          item.title = name
          return
        }
      })
    },
  }
  ,

//离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (!this.returnPageStatus) {
      sessionStorage.removeItem('listSearch')
    }
    if (this.programExit) {
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
      })
      next();
      // 关闭当前的页签
      this.$store.dispatch('tagsView/delView', from).then(({visitedViews}) => {
      })
    } else if (this.butType == 0 || this.butType == 2 || this.butType == 4) {
      this.$confirm('当前数据未保存，您确定退出吗？', '提示', {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 关闭当前的页签
        if (this.returnPageStatus) {
          this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
          })
        }
        //进去别的页面
        next();
      }).catch(() => {
        this.$store.dispatch('tagsView/addVisitedView', from)
        next(false);
      });
    } else {
      // 关闭当前的页签
      this.$store.dispatch('tagsView/delView', from).then(({visitedViews}) => {
      })
      next();
    }
  }
  ,
}
</script>

<style scoped>
.contens {
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.minTitle {
  font-weight: bold;
  font-size: 14px;
}

.fileIcon {
  cursor: pointer;
  font-size: 30px;
}

.baseTip {
  text-align: center;
  height: calc(100vh - 450px);
  line-height: calc(100vh - 450px);
}

.zheaug {
  position: relative;
}

.zheuag {
  position: absolute;
  background: rgba(192, 200, 217, 0.3);
  z-index: 99;
  width: 100%;
  height: 150px;
  top: 0;
  left: 0;
}

.zheuag2 {
  background: transparent;

  .adfad1 {
    width: 32%;
    height: 100%;
    background: rgba(192, 200, 217, 0.3);
  }
}
</style>
